// Core variables and mixins
@import "../../bootstrap/functions";
@import "../../bootstrap/mixins";

// Core variables and mixins overrides
@import "../../core/variables/bootstrap-variables";
@import "../../bootstrap/variables";

@import "../../core/variables/components-variables";


$primary-color: $gray-600 !default;
$ball-size: 15px !default;
$margin: 2px !default;
$line-height: 35px !default;
$line-width: 4px !default;
