.modal-sm {
	max-width: 400px;
}
.modal-xs {
	max-width: 300px;
}
.modal-xl {
	max-width: 94%;
	margin-left: 3%;
	margin-right: 3%;
}
.icon-align{
	margin-top: 0.6rem;
    margin-left: 0.5rem;
}