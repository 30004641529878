/*=========================================================================================
	File Name: horizontal-menu.scss
	Description: A classic horizontal menu for easy navingation & support all devices.
	It support light & dark version, filpped layout, right side icons, borders menu for
	item seperation.
	----------------------------------------------------------------------------------------
Item Name: Item Name: Modern Admin -Angular 10+ Bootstrap 4 Admin Dashboard Template
	Version: 3.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// Core variables and mixins
@import "../../../bootstrap/functions";
@import "../../../bootstrap/mixins";
@import "../../../bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../variables/bootstrap-variables";
@import "../../../bootstrap/variables";

@import "../../variables/components-variables";

// Import first main menu mixin
@import "../../mixins/main-menu-mixin";


// Horizontal Menu
//=========================
.horizontal-menu {
  .navbar-horizontal .nav-item {
    i {
      font-size: 1.6rem;
      margin-right: 0.5rem;
    }

    a span {
      font-size: 1.1rem;
      position: relative;
      top: -2px;
    }
  }

  //navbar-icon-right
  .navbar-horizontal{
    &.navbar-icon-right{
      .nav-item {
        i {
          top: -4px;
          position: relative;
        }
      }
    }
  }

  .navbar-horizontal ul#main-menu-navigation>li>a {
    padding: 1.8rem 1rem;
  }

  #main-menu-navigation {
    ul {
      li {
        a {
          span {
            transition: all 0.2s ease;
            display: inline-block;

            &:hover,
            .hover {
              transform: translateX(4px);
            }
          }
        }
      }
    }
  }

  &.menu-collapsed {
    #main-menu-navigation {
      .nav-item > {
        a > span {
          display: none;
        }
      }
    }
  }

  .navigation-header {
    font-family: inherit;
    color: #929292;
    padding: 8px 20px;
    font-size: 1rem;
  }

  .navbar-flipped {
    .navbar-nav {
      .nav-item {
        float: right;
      }
    }
  }

  .navbar-light {
    ul#main-menu-navigation>li.active>a {
      border-bottom: 1px solid $info;
      background: $white;
    }

    .active>a {
      background: whitesmoke;
      font-weight: 500;
    }
  }

  .navbar-dark {
    .nav-item {
      &.active>a {
        // border-bottom: 2px solid $primary;
        background-color: rgba(255, 255, 255, 0.05);
      }

      &:hover,
      .hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    .active>a {
      background: whitesmoke;
      font-weight: 500;
    }
  }

  &[data-col="content-left-sidebar"] .sticky-wrapper {
    float: none;
  }
}

// margin right for icons of sub-menu of main-menu
.horizontal-layout {
  .main-menu .navigation>li{
    >ul>li>a i {
      margin-right: 0.5rem;
    }
  }
}

@media (max-width: 767px) {
  .horizontal-layout {
    &.horizontal-menu-padding{
      .header-navbar{
        &.navbar-with-menu {
          .navbar-container{
            max-width: 100%;
          }
        }
      }
    }
  }
}

// Import vertical-overlay-menu.scss for small screen support
@import "vertical-overlay-menu.scss";

