// Toasts
.toast-bs-container{
  z-index: 1040;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  .toast-position{
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap-reverse;

    .toast.show{
      // display: block;
      opacity: 1;
      z-index: 1030;
    }
  }
}
.toast-placement{
  position: static;
  top: 0;
  .toast{
    position: absolute;
    top: 0;
    z-index: 1030;
  }
}



.toast {
  display: none;
  background-color: rgba(255, 255, 255,1);
  margin-top: $toast-padding-x;
  // position: absolute;
}

.toast-body{
  background-color: $body-bg;
}
.toast.show.toast-wrapper{
  position: static;
}


.toast.toast-translucent {
  opacity: 1;
  display: block;
}

.toast-header{
	img{
		width: 20px;
		height: 20px;
	}
}