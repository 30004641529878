.content-head-image{
	.content{
		.content-wrapper{
			.content-header{
				height: 200px;
				padding: 2rem 1rem 0;
				margin: 0 0 3rem;
				position: relative;
				&::before{
					content: '';
					background-color: $black;
					opacity: 0.3;
					width: 100%;
					height: 200px;
					position: absolute;
					top: 0;
					left: 0;
				}
				.content-header-title{
					color: $white;
					font-size: 2rem;
				}
				.breadcrumbs-top{
					position: absolute;
					bottom: 0rem;
					.breadcrumb{
						li{
							a, &::before, &::after{
								color: $white;
							}
							&.active{
								color: $white;
								font-weight: 500;
							}
						}
					}
				}
			}
		}
	}
}