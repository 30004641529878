// ================================================================================================
// 	File Name: user-feed.scss
// 	Description: Page content different types of users page layouts SCSS.
// 	----------------------------------------------------------------------------------------------
//    Item Name: Item Name: Modern Admin -Angular 10+ Bootstrap 4 Admin Dashboard Template
//    Version: 3.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

.profile-image{
    margin-top: -130px;
}
.nav-margin{
    margin-top: -75px;
    margin-bottom: 35px;
}
.width-30{
    width: 30px;
}
.mrb-5px{
    margin-right: 5px;
    margin-bottom: 5px;
}

// Media Query

@media only screen and (max-width: 998px) {
    .navbar-expand-lg{
    	display: none;
    }
}
@media only screen and (max-width: 576px) {
    .profile-image{
    	width: 100px !important;
    }
}

#user-feed{
    i{
        &:hover{
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 1200px) {
    #user-feed{
        .navbar-nav{
            .nav-item{
                padding: 0 1rem !important;
            }
        }
    }
}
