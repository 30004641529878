// Remove focus/active focus outline
.btn {
  &.btn-square{
    @include button-square-variant(0);
  }
  &.btn-round{
    @include button-round-variant(2rem);
  }
  &:focus,&.focus,&:active, &.active {
    outline: none;
    box-shadow: none !important;
  }

  &.btn-icon{
    i{
      font-size: 1.1rem;
    }
    &.btn-pure{
      background-color: transparent;
      border-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      i{
        font-size: 1.25rem;
      }
    }
  }
  &.btn-social{
    padding: 0.7rem 0.25rem 0.85rem 3.75rem;
    //0.5rem 0.25rem 0.5rem 2.75rem;
  }
}
.btn-min-width{
  min-width:8.5rem;
}

.color-box{
  width: 100%;
  height: 2.75rem;
  border-radius: 0.25rem;
}
.btn-float {
  padding: 15px;
  white-space: normal;
  -webkit-box-shadow: 0 6px 10px rgba(0,0,0,.15);
  box-shadow: 0 6px 10px rgba(0,0,0,.15);
  &.btn-link {
    padding: 10px;
  }
  i {
    display: block;
    margin: 0;
    top: 0;
  }
  &.btn-float-lg i, &.btn-float-lg span{
    font-size: 2rem;
  }
  &.btn-float-lg i + span{
    font-size: 1.1rem;
  }
  > i + span {
    display: block;
    padding-top: 10px;
    margin-bottom: -6px;
  }
}