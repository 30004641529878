// tags

@mixin badge-variant($color) {
  background-color: $color;
  &.badge-glow{
    box-shadow: 0px 0px 10px $color;
  }
  &[href] {
    @include hover-focus {
      background-color: darken($color, 10%);
    }
  }
}