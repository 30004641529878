/* You can add global styles to this file, and also import other style files */
/*@import "~bootstrap/dist/css/bootstrap.css";

body{
    background-color: #f7f7f7 !important;
}

.margin-top-70{
    margin-top: 70px;
}*/
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

:host ::ng-deep .email-fixed-header {
    min-height: calc(100% - 207px) !important;
    margin-top: 0 !important;
    overflow-y: hidden !important;
}

.navbar-semi-dark .navbar-header {
    background-color: #333645 !important;
}

.main-menu.menu-dark .navigation {
    background-color: #333645 !important;
}

.main-menu.menu-dark, .vertical-overlay-menu.menu-hide .menu-dark.main-menu {
    background-color: #333645 !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

[app-tooltip]:before {
    content: attr(app-tooltip);
    background: #585858;
    padding: 5px 7px;
    border-radius: 2px;
    color: #FFF;
    font: 500 12px Roboto;
    white-space: nowrap;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    right: 100%;
    bottom: 15px;
}

[app-tooltip]:hover:before {
    visibility: visible;
    opacity: 1;
}

[app-right-tooltip]:before {
    content: attr(app-right-tooltip);
    background: #585858;
    padding: 5px 7px;
    margin-right: 10px;
    border-radius: 2px;
    color: #FFF;
    font: 500 12px Roboto;
    white-space: nowrap;
    position: absolute;
    left: 75px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 11;
}

[app-right-tooltip]:hover:before {
    visibility: visible;
    opacity: 1;
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.btn-danger, .btn-primary, .btn-success, .btn-secondary, .btn-warning {
    border: none !important;
}

.mat-tab-body-content {
    overflow: hidden !important;
}

.donut-chart2 .ct-label {
    fill: #28d094 !important
}

.w-fc {
    width: fit-content !important;
}


:host ::ng-deep .content-right {
    width: calc(100% - 300px) !important;
}

:host ::ng-deep .mx-75 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
}

:host ::ng-deep .py-75 {
    padding-bottom: .75rem !important;
    padding-top: .75rem !important;
}

:host ::ng-deep .px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

:host ::ng-deep .mr-50, .mx-50 {
    margin-right: .5rem !important;
    margin-left: .5rem !important;
}

:host ::ng-deep .mb-50, .my-50 {
    margin-bottom: .5rem !important;
}

:host ::ng-deep .dropdown-menu {
    transform: translate3d(-131px, 18px, 0px) !important;
}

:host ::ng-deep .chat-sidebar .chat-sidebar-list-wrapper {
    overflow-y: scroll;
}

:host ::ng-deep .sidebar-fixed {
    overflow: hidden !important;
    width: 300px;
}

:host ::ng-deep .app-content {
    overflow-y: hidden !important;
}

:host ::ng-deep .pb-25, .py-25 {
    padding-bottom: .25rem !important;
}

:host ::ng-deep .ml-25, .mx-25 {
    margin-left: .25rem !important;
}

@media (max-width: 767px) {
    :host ::ng-deep .chat-footer {
        position: relative !important;
        bottom: 17px !important;
    }
}

@media (max-width: 767px) {
    :host ::ng-deep .chat-wrapper {
        background-color: unset !important;
    }
}

@media (max-width: 767px) {
    :host ::ng-deep .content-right {
        width: calc(100% - 0px) !important;
    }
}

:host ::ng-deep .d-inline-block {
    display: inline !important;
}

.chat-message {
    min-width: 100px;
}

.chat-wrapper {
    height: 350px;
    overflow-y: auto;
}

.close span {
    font-size: 2rem !important;
}

.modal-xxl {
    min-width: 100vw !important;
}

button[data-toggle="tooltip"] {
    container: 'body'
}

#brand {
    margin-top: 20px !important;
}

#navbar-header > ul > li.nav-item.d-none.d-md-block.nav-toggle > a > i{
    display: none !important;
}
